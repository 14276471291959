<template>
  <div>
    <b-row class="p-5 mt-5 justify-content-center">
      <template>
        <b-col
          class="mb-5 text-center"
          v-for="(program, index) in programs"
          :key="index"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <div class="mb-1 img-hover">
            <img
              :src="baseImg + program.img"
              height="150"
              alt="Logo"
              rounded="circle"
              class="cursor-pointer"
              @click="redirectToProgram(program.initial)"
            />
          </div>
          <h4>{{ program.name }}</h4>
        </b-col>
      </template>
    </b-row>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      baseImg: process.env.VUE_APP_BASE_URL_ASSETS,
      mainProps: {
        width: 150,
        height: 150,
        class: "p-1",
        background: "#ccc",
      },
      programs: [
        {
          id: 1,
          name: "Business",
          initial: "bu",
          img: "/images/modules-icons/business.png",
        },
        {
          id: 2,
          name: "Boost Credit",
          initial: "bc",
          img: "/images/modules-icons/bootscredit.png",
        },
        {
          id: 3,
          name: "Credit Experts",
          initial:"ce",
          img: "/images/modules-icons/creditexperts.png",
        },
        {
          id: 4,
          name: "Debt Solution",
          initial:"ds",
          img: "/images/modules-icons/debtsolution.png",
        },
        {
          id: 5,
          name: "Tax Research",
          initial:"tr",
          img: "/images/modules-icons/taxresearch.png",
        },
      ],
    };
  },

  methods: {
    redirectToProgram(slug) {
      this.$router.push({
        name: "list-source-methods-social-network",
        params: { program:slug },
      });
    },
  },
};
</script>
  
  <style>
.img-hover img {
  -webkit-transition: all 0.3s ease; /* Safari and Chrome */
  -moz-transition: all 0.3s ease; /* Firefox */
  -o-transition: all 0.3s ease; /* IE 9 */
  -ms-transition: all 0.3s ease; /* Opera */
  transition: all 0.3s ease;
  position: relative;
}
.img-hover img:hover {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.1); /* Safari and Chrome */
  -moz-transform: scale(1.1); /* Firefox */
  -ms-transform: scale(1.1); /* IE 9 */
  -o-transform: translatZ(0) scale(1.1); /* Opera */
  transform: translatZ(0) scale(1.1);
}

.img-hover:hover:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 30px;
  height: 30px;
  border: 1px solid #000;
}
</style>